.jMnaAq_container {
  grid-template-columns: minmax(0, max-content);
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: auto;
}

.jMnaAq_container.jMnaAq_fit {
  grid-template-rows: minmax(0, 100%);
  grid-template-columns: auto;
  overflow: hidden;
}

.jMnaAq_container.jMnaAq_fit img {
  max-width: 100%;
  max-height: 100%;
}
/*# sourceMappingURL=index.eda4afe0.css.map */
